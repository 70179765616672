<template>
  <div class="page-content">
    <div class="card">
      <div class="card-header">
        <b>{{ channel_obj.name }} Profile</b>
      </div>
      <div class="card-body row">
        <edit-whatsapp v-if="channel_type === 'whatsapp'" ref="whatsapp_component" />
        <edit-webchat v-if="channel_type === 'webchat'" ref="webchat_component" />

        <div class="d-flex float-right">
          <!-- <el-button @click="updateProfile" :loading="loading.update_profile" class="btn mr-2 btn-primary">{{ $t('general.submit') }}</el-button> -->
          <b-button type="reset" variant="secondary" @click="back">Back</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  email, maxLength, url, helpers,
} from '@vuelidate/validators';
// import popupErrorMessages from '../../../library/popup-error-messages';
// import 'babel-polyfill';
// import ImageCrop from 'vue-image-crop-upload/upload-2.vue';
import channelsAPI from '../../../api/channels';
import EditWhatsapp from '../../components/channels/EditWhatsapp.vue';
import EditWebchat from '../../components/channels/EditWebchat.vue';

export default {
  name: 'ChannelProfile',
  metaInfo: {
    title: 'Channel Profile',
  },
  components: {
    // ImageCrop,
    EditWhatsapp,
    EditWebchat,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form_profile: {
        email: { email, maxLength: maxLength(128) },
        about: { maxLength: maxLength(256) },
        address: { maxLength: maxLength(256) },
        description: { maxLength: maxLength(512) },
        websites: {
          maxLength: maxLength(2),
          $each: helpers.forEach({
            url: {
              url,
              maxLength: maxLength(256),
            },
          }),
        },
      },
    };
  },
  data() {
    return {
      profile: {},
      channel_type: '',
      channel_obj: {},
      channel_list: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
      loading: {
        update_profile: false,
      },
      form_profile: {
        about: '',
        address: '',
        description: '',
        email: '',
        websites: [],
        base64_profile: '',
      },
      selected_file_profile: null,
      showCropImage: false,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getChannelProfile();
  },
  methods: {
    back() {
      this.$router.back();
      // this.$router.push('/settings/channels');
    },
    toggleShow() {
      this.showCropImage = !this.showCropImage;
    },
    // cropSuccess(imgDataUrl, field){
    cropSuccess(imgDataUrl) {
      console.log('-------- crop success --------');
      // this.imgDataUrl = imgDataUrl;
      this.form_profile.base64_profile = imgDataUrl;
    },
    async getChannelProfile() {
      this.$parent.showLoader();
      await channelsAPI.getChannelProfile({
        workspace_id: this.activeWorkspace._id || this.$route.params.workspace_id,
        channel_id: this.$route.params.channel_id,
      }).then(({ data }) => {
        const { channel_type, profile } = data;
        this.channel_type = channel_type;
        this.channel_obj = this.channel_list.find((value) => value.value === channel_type);
        Object.keys(this.form_profile).forEach((key) => {
          if (key === 'websites') {
            this.$set(this.form_profile, key, profile[key].map((v) => ({ url: v })));
          } else {
            this.$set(this.form_profile, key, profile[key]);
          }
        });
        this.profile = profile;
        if (profile.photo) {
          this.profile.profile_picture_url = profile.photo;
        }
        if (channel_type === 'whatsapp') {
          this.$nextTick(() => {
            this.$refs.whatsapp_component.getChannelProfile();
          });
        } else if (channel_type === 'webchat') {
          this.$nextTick(() => {
            this.$refs.webchat_component.getChannelProfile();
          });
        }
      }).catch((err) => console.log(err));
      this.$parent.hideLoader();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
          reject(error);
        };
      });
    },
    async updateProfile() {
      this.v$.form_profile.$touch();
      console.log(this.v$.form_profile.$errors);
      if (this.v$.form_profile.$error) return;
      this.$parent.showLoader();
      const form_obj = cloneDeep(this.form_profile);
      form_obj.websites = form_obj.websites.map((v) => v.url);
      await channelsAPI.updateChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        data: form_obj,
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Profile updated.',
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Failed to update profile.',
        });
      });
      this.$parent.hideLoader();
    },
    async handleInputNewProfilePhoto(value) {
      this.form_profile.base64_profile = await this.getBase64(value);
    },
    handlerBtnAddMoreWebsite() {
      if (this.form_profile.websites.length === 2) {
        this.$message({
          type: 'warning',
          message: 'Maximum 2 websites',
        });
        return;
      }
      this.form_profile.websites.push({ url: '' });
    },
    handlerRemoveWebsites(index) {
      this.form_profile.websites.splice(index, 1);
    },
  },
};
</script>
